<template>
        <div class="d-flex justify-content-center">
          <ISALanguageSelector style="top: 0; right: 2rem;position: absolute"/>
      <img :src="IMGService.isalogo" class="brand_logo" alt="Logo" style="margin-top: 20px"/>      
      <div class="form_container" >
        <form style="margin-top: 20px">
          <div class="user-thumb text-center m-b-30">
            <img
              :src="IMGService.isalock"
              class="rounded-circle img-thumbnail"
              alt="thumbnail"
              style="height: 80px; padding-top:-10px"
              v-b-toggle.collapseAdvanced
            />
          </div>
          <div style="padding-top: 10px; height: 315px; position: relative; bottom:0px">   
          
          <validation-observer ref="observer" v-slot="{ handleSubmit }" v-if="!hideButton">
              <b-form @submit.prevent="handleSubmit(apply)" v-if="!hideButton" id="recover_password_form" >
                <h1 v-if="!hideButton" class="text-12">{{ $t('Please, enter your mail address to reset your password.')}}</h1>
                <validation-provider :name="$t('Email')" :rules="{ required: true, regex: /^[^@]+@[^@]+\.[a-z]{2,}(_[a-z]+)*$/i }" v-slot="validationContext">
                <b-form-group                                    
                  label-align-sm="left"
                  label-for="email"
                >
                  <b-form-input v-model="email" :placeholder="$t('email address')" class="form-control-rounded"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-3-live-feedback"
                    id="mail_field"
                  ></b-form-input>
                  <b-form-invalid-feedback
                      id="input-3-live-feedback"
                    >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
                </validation-provider>
  
                <div class="input-group mb-3" style="margin-top: 20px; width: 100%" v-if="!hideButton">
                        <b-button
                          type="submit"
                          tag="button"
                          class="btn-rounded btn-block mt-2 signinbtn text-12"
                          variant="primary mt-2"
                          :disabled="requesting"
                          id="recover_password_button"
                        >
                         {{ $t('Reset Password')  }}
                        </b-button>                      
                    </div>


              </b-form>
          </validation-observer>

          <h2 class="resettext" v-if="hideButton" id="recover_password_success">{{ $t('An email has been sent to your address, containing a unique link allowing you to reset your password.')}}</h2>
          <div class="mt-3 text-center ">
            <router-link to="signIn" tag="a" class="text-12" v-if="!modal" id="recover_password_back">
              <u>{{ $t('Back') }}</u>
            </router-link>
            <a class="text-muted" v-if="modal" @click="backClick"><u>{{$t('Back')}}</u></a>
          </div>
          </div>
          </form>
        </div>
      </div>
       
</template>

<script>
import { AuthService } from "@/services/auth.service";
import { API_URL } from '@/.env'
import ISALanguageSelector from './ISALanguageSelector';
import { IMGService } from '@/services/img.service'

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Forgot Password"
  },
  components: {
    ISALanguageSelector
  },
  props: {
    modal: {
      type: Boolean,
      required: false,
      default: false,      
    },
  },
  data() {
    return {
      IMGService,
      bgImage: IMGService.bgImage,
      logo: IMGService.isalogo,      
      email: '',
      hideButton: false,
      requesting: false,
    };
  },
  mounted() {
    this.email = '';
    this.hideButton = false;
  },
  methods: {
    apply() {        
        this.hideButton = true;
        AuthService.resetPasswordRequest(API_URL.url, this.email);   
        this.$bvToast.toast(this.$t('An email has been sent to reset your password.'), {
              title: this.$t('Password Reset'),            
              solid: true,
              variant: 'success',
              duration: 5000,
              id: "recover_pw_alert"
            });
        if(!this.modal)
            setTimeout(() => {
                this.$router.push('/app/sessions/signIn');
            }, 15000)
        else 
        {
          setTimeout(() => {
                this.$store.dispatch("user/needsForgot", false);
            }, 15000)
        }

    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    backClick() {
      this.$store.dispatch("user/needsLogin", true);      
      this.$store.dispatch("user/needsForgot", false);
    }
  }
};
</script>


<style lang="scss" scoped>
.isa-primary {
  background-color:#36958d !important;
}

.resettext {
  color: #36958d !important;
}

.rounded {
  border-radius: 20px;
}

.user_card {
  height: 400px;
  width: 100%;
  margin-top: 50px;
  margin-bottom: auto;
  background: white;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.brand_logo {
  position: absolute;
  text-align: center;
  height: 55px;
}
.form_container {
  margin-top: 100px;
}
.login_btn {
  width: 100%;
  background: #36958d !important;
  color: white !important;
}
.login_btn:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.login_container {
  padding: 0 2rem;
}
.input-group-text {
  background: #36958d !important;
  color: white !important;
  border: 0 !important;
  border-radius: 0.25rem 0 0 0.25rem !important;
}
.input_user,
.input_pass:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #c0392b !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #36958d;
  opacity: 0.6; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #36958d;
  opacity: 0.6; /* Firefox */
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #36958d;
  opacity: 0.6; /* Firefox */
}

.btn:focus {
  outline: none !important;
}

.btn-secondary {
  border-color: white;
}

.nav-pills {
    background-color: white;
}

.text-12 {
  font-weight: bold;
  color: rgb(0, 0, 0);
}

.text-muted {
  font-weight: bold;
  color: rgb(87, 80, 80) !important;
}

.signinbtn {
  color:  rgb(0, 0, 0);
  font-weight: bold;
}

@media screen and (max-width: 640px) {
    .text-muted {
        color: rgb(129, 122, 122) !important;
    }
}

</style>
