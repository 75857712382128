<template>           
      <div class="col-1">
        <b-dropdown tsize="lg"  variant="link" toggle-class="text-decoration-none" no-caret dropdown right>    
          <template v-slot:button-content >       
            <span>{{$i18n.locale.toUpperCase()}}</span>
          </template>
          <b-dropdown-item href="#" @click="changeLocale('en')">English</b-dropdown-item>
          <b-dropdown-item href="#" @click="changeLocale('nl')">Nederlands</b-dropdown-item>
          <b-dropdown-item href="#" @click="changeLocale('fr')">Français</b-dropdown-item>
        </b-dropdown> 
      </div>    
  
</template>

<script>
import { AuthService } from '@/services/auth.service'

import {  localize } from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import nl from "vee-validate/dist/locale/nl.json";
import fr from "vee-validate/dist/locale/fr.json";




export default {
  name: 'ISALanguageSelector',
  components: {

  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale;
      this.saveLanguage();
      window.location.reload();
    },
    async logout () {
      await AuthService.makeLogout()
      if(!this.$store.state.user.kioskUser.oid)
        this.$router.push('/app/session/signIn')
    },
    async login () {
      // we need to start a modal for login
      this.$store.dispatch('user/needsLogin',true);
    },
    hideContent () {
      if (this.isShown) this.isShown = false
    },
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    toggleNotificationDropDown () {
      this.activeNotifications = !this.activeNotifications
    },
    closeDropDown () {
      this.activeNotifications = false
    },
    toggleSidebar () {      
        
        this.$store.commit('user/SET_KIOSKMODE',!this.$store.state.user.kioskMode)
    },
    hideSidebar () {
      this.$sidebar.displaySidebar(false)
    },
    changePattern() {
       this.$store.commit('user/SET_NEEDS_CHANGEPATTERN',true)
    },
    changePassword() {
       this.$store.commit('user/SET_NEEDS_CHANGEPASSWORD',true)
    }, 
    saveLanguage() {
      window.localStorage.setItem('MYISA_LANG',this.$i18n.locale);
      localize(this.$i18n.locale)
    }
  },
  data() {
    return {
      
    }
  },  
  computed: {
      routeName () {
        const {name} = this.$route
        return this.capitalizeFirstLetter(name)
      },
      isLogged() {
          return (this.$store.state.user.currentUser.username && this.$store.state.user.currentUser.role > 0)
      },
      showLogin() {        
        return (!this.$store.state.user.currentUser.username || this.$store.state.user.currentUser.role === 0)  && this.$router.currentRoute.path.indexOf('authorize') < 0                
      },
      iconChar() {
        if(this.$store.state.user.currentUser.username && this.$store.state.user.currentUser.role > 0)
          return '&#x1F464;'
        else return '&#128274;'
      },
      navclass() {
        if(!this.$store.state.user.kioskMode && !this.$store.state.user.kioskPage)
          return "administration"
        else return "kiosk"
      },
  },  
  mounted() {
    let lang = window.localStorage.getItem('MYISA_LANG');
    if(lang)
    {
      this.$i18n.locale = lang;
      localize(lang);
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-text {
    color: #ffffff;
    text-align: center;
    font-weight: 400;
    line-height: 30px;
  }

.header {
  z-index: 1;
  padding: 20px 0;
  position: relative;

  .is-active {
    color: #e01b3c;
  }

  a {
    color: #000;
    text-decoration: none;
  }

  .menu-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  

  .menu {
    font-size: 25px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;

    li {
      padding: 15px;

    }

    &.side {
      font-size: 15px;

      .logout-button {
        cursor: pointer;
      }
    }
  }
}
</style>
