<template>
  <div class="auth-layout-wrap" :style="{ backgroundImage: 'url(' + bgImage + ')' }">
      <div class="container">
        <div class="d-flex justify-content-center h-100">        
          <div class="card">  
            <ISAForgot />
            </div>            
      </div>
    </div>
  </div>
  
</template>
<script>
import ISAForgot from '@/components/ISA/session/ISAForgot';
import { IMGService } from '@/services/img.service'
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Forgot Password"
  },
  components: {
    ISAForgot
  },
   data() {
    return {
      bgImage: IMGService.bgImage,
      logo: IMGService.isalogo,      
    };
  },
};
</script>


<style lang="scss" scoped>

.spinner.sm {
  height: 2em;
  width: 2em;
}

.auth-layout-wrap {    
    align-items: center;
    
}

@media screen and (max-width: 640px) {
    .auth-layout-wrap {
        justify-content: unset;
    }
}

.auth-layout-wrap .auth-content {
    max-width: 400px;
    margin: 5px;
}

.container{
  height: 100%;
  align-content: center;
}

.card{
  height: 385px;
  margin-top: auto;
  margin-bottom: auto;
  width: 400px;
  background-color: rgba(236, 234, 234, 0.397) !important;
}

</style>
